.custom-hexagon {
  clip-path: polygon(75% 0%, 80% 50%, 75% 100%, 0% 100%, 0 50%, 0% 0%);
}

.custom-hexagon:hover
{
  background: linear-gradient(179.26deg, #0F28A2 6.68%, rgba(209, 209, 12, 0.43) 99.36%);
}


.custom-label {
  background: linear-gradient(90deg, #6243a3 0%, #9073d1 51.31%, #6243a3 100%);
}

.custom-btn {
  background: linear-gradient(180deg,
      #e89f9f 0.01%,
      #eb2a22 9.38%,
      #f0504a 50%,
      #fb3029 88.02%,
      #c86e6b 99.48%);
}

.custom-color {
  background: linear-gradient(179.25deg,
      #0f28a2 4.67%,
      rgba(113, 137, 173, 0.85) 97.34%),
    linear-gradient(0deg, #1c35ab, #1c35ab);
  border-color: rgba(59, 75, 159, 1);
}

.custom-bordercolor {
  border-color: rgba(59, 75, 159, 1);
}

.circle-container {
  border: 3px solid #fcd16b;
  background-color: #594284;
  height: 144px;
  border-radius:50%;
  width: 144px;
  color: white;
}

.rhombus {
  background-color: #594284;
  border:3px solid #fcd16b;
  height: 124px;
  text-align: center;
  transform:rotate(45deg);
	width: 124px;

}
.item-count {
  color: white;
  display: table-cell;
  transform: rotate(-45deg);
  vertical-align: middle;
}
.bouncing-ball1 {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #000102;
    animation-name: bounce1;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 0.9s;
}

.bouncing-ball2 {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #000102;
    animation-name: bounce2;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 0.9s;
}

.bouncing-ball3 {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #000102;
    animation-name: bounce3;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 0.9s;
}

@keyframes bounce1 {
    0% {
        transform: translateY(7px);
    }

    25% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-7px);
    }

    75% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(7px);
    }
}

@keyframes bounce2 {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(7px);
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(-7px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes bounce3 {
    0% {
        transform: translateY(-7px);
    }

    25% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(7px);
    }

    75% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(-7px);
    }
}
.wave{
    clip-path: polygon(100% 0%, 0% 0% , 0.00% 78.45%, 1.00% 78.71%, 2.00% 78.94%, 3.00% 79.14%, 4.00% 79.31%, 5.00% 79.44%, 6.00% 79.55%, 7.00% 79.62%, 8.00% 79.66%, 9.00% 79.66%, 10.00% 79.64%, 11.00% 79.58%, 12.00% 79.49%, 13.00% 79.36%, 14.00% 79.20%, 15.00% 79.02%, 16.00% 78.80%, 17.00% 78.55%, 18.00% 78.27%, 19.00% 77.96%, 20.00% 77.62%, 21.00% 77.25%, 22.00% 76.86%, 23.00% 76.44%, 24.00% 76.00%, 25.00% 75.53%, 26.00% 75.04%, 27.00% 74.53%, 28.00% 74.00%, 29.00% 73.46%, 30.00% 72.89%, 31.00% 72.31%, 32.00% 71.71%, 33.00% 71.10%, 34.00% 70.48%, 35.00% 69.86%, 36.00% 69.22%, 37.00% 68.57%, 38.00% 67.93%, 39.00% 67.27%, 40.00% 66.62%, 41.00% 65.97%, 42.00% 65.32%, 43.00% 64.67%, 44.00% 64.03%, 45.00% 63.39%, 46.00% 62.76%, 47.00% 62.14%, 48.00% 61.54%, 49.00% 60.94%, 50.00% 60.36%, 51.00% 59.80%, 52.00% 59.25%, 53.00% 58.73%, 54.00% 58.22%, 55.00% 57.73%, 56.00% 57.27%, 57.00% 56.83%, 58.00% 56.42%, 59.00% 56.03%, 60.00% 55.67%, 61.00% 55.33%, 62.00% 55.03%, 63.00% 54.75%, 64.00% 54.50%, 65.00% 54.29%, 66.00% 54.10%, 67.00% 53.95%, 68.00% 53.83%, 69.00% 53.74%, 70.00% 53.69%, 71.00% 53.67%, 72.00% 53.68%, 73.00% 53.72%, 74.00% 53.80%, 75.00% 53.91%, 76.00% 54.05%, 77.00% 54.22%, 78.00% 54.42%, 79.00% 54.66%, 80.00% 54.92%, 81.00% 55.22%, 82.00% 55.54%, 83.00% 55.89%, 84.00% 56.27%, 85.00% 56.68%, 86.00% 57.11%, 87.00% 57.56%, 88.00% 58.04%, 89.00% 58.54%, 90.00% 59.06%, 91.00% 59.60%, 92.00% 60.16%, 93.00% 60.73%, 94.00% 61.32%, 95.00% 61.92%, 96.00% 62.54%, 97.00% 63.16%, 98.00% 63.80%, 99.00% 64.44%, 100.00% 65.08%);
}
.opacitybg{
    background: rgba(255, 255, 255, 0.7) !important;
    backdrop-filter: blur(5px);
}
.shadowheader{
    background: linear-gradient(270deg, #C6C1E7 0%, #0A379F 100%);
    box-shadow: inset 0 8px 10px -5px #05369f4e;
}
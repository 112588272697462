.custom-tooltip {
  border: 3px solid #99c8f3 !important;
  color: #0c399f !important;
  font-weight: "bold" !important;
  z-index: 50;
}

.react-tooltip-arrow {
  border-bottom: 8px solid #99c8f3;
  border-right: 4px solid #99c8f3;
}
.core-styles-module_show__Nt9eE {
  opacity: 5.5 !important;
}

@keyframes Table1 {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-250px);
  }
}

.animate-Table1 {
  animation: Table1 1s ease-in forwards;
}

@media (min-width: 1200px) {
  @keyframes Table1 {
    0% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(-300px);
    }
  }

  .animate-Table1 {
    animation: Table1 1s ease-in forwards;
  }
}

@keyframes animateFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animateFadeIn {
  animation: animateFadeIn 3s linear forwards;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}
.Toastify__toast-container--top-center {
  top: 50% !important;
  left: 50% !important;
  transform: translateX(-50%);
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1 1;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: linear-gradient(
    95deg,
    #f6743e -6.96%,
    #d42525 108.25%
  ) !important;
}

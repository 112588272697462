.text-blue-500 {
  color: #3b82f6;
}
.border-blue-500 {
  border: #3b82f6;
  border-style: solid;
}
.border-yellow-500 {
  color: #eab308;
}
.text-yellow-500 {
  border: #eab308;
  border-style: solid;
}
.border-purple-500 {
  color: #a855f7;
}
.text-purple-500 {
  border: #a855f7;
  border-style: solid;
}
.text-teal-500 {
  color: #14b8a6;
}
.border-teal-500 {
  border: #14b8a6;
  border-style: solid;
}

.text-cyan-500 {
  color: #17c1e8;
}
.border-cyan-500 {
  border: #17c1e8;
  border-style: solid;
}
.text-indigo-500 {
  color: #6366f1;
}
.border-indigo-500 {
  border: #6366f1;
  border-style: solid;
}

.text-lime-500 {
  color: #82d616;
}
.border-lime-500 {
  border: #82d616;
  border-style: solid;
}
.bg-1 {
  background-color: #fff0f1;
}
.text-1 {
  color: #fc8e94;
}
.border-1-clr {
  border: #f2a9ad;
  border-style: solid;
}

.bg-2 {
  background-color: #f5eeff;
}
.text-2 {
  color: #9a72d8;
}
.border-2-clr {
  border: #9a72d8;
  border-style: solid;
}

.bg-3 {
  background-color: #e4f8ff;
}
.text-3 {
  color: #5fc2e2;
}
.border-3-clr {
  border: #5fc2e2;
  border-style: solid;
}
.bg-4 {
  background-color: #e3fff9;
}
.text-4 {
  color: #6ae0c7;
}
.border-4-clr {
  border: #6ae0c7;
  border-style: solid;
}
.bg-5 {
  background-color: #f3fff5;
}
.text-5 {
  color: #7bd784;
}
.border-5-clr {
  border: #7bd784;
  border-style: solid;
}
.bg-6 {
  background-color: #fbeff1;
}
.text-6 {
  color: #ba324f;
}
.border-6-clr {
  border: #ba324f;
  border-style: solid;
}
.bg-7 {
  background-color: #fef2eb;
}
.text-7 {
  color: #f9844a;
}
.border-7-clr {
  border: #f9844a;
  border-style: solid;
}
.bg-8 {
  background-color: #f4f9f1;
}
.text-8 {
  color: #90be6d;
}
.border-8-clr {
  border: #90be6d;
  border-style: solid;
}
.bg-9 {
  background-color: #feebec;
}
.text-9 {
  color: #f94144;
}
.border-9-clr {
  border: #f94144;
  border-style: solid;
}

.bg-10 {
  background-color: #fbf3ee;
}
.text-10 {
  color: #dfa081;
}
.border-10-clr {
  border: #dfa081;
  border-style: solid;
}

.bg-11 {
  background-color: #ebfcff;
}
.text-11 {
  color: #009fb7;
}
.border-11-clr {
  border: #009fb7;
  border-style: solid;
}

.bg-12 {
  background-color: #fcf6ed;
}
.text-12 {
  color: #e8ae68;
}
.border-12-clr {
  border: #e8ae68;
  border-style: solid;
}

/* #game1reset{
    height: 40%;
    width: 100%;
}
#game1AStep1segmentation{
    height: 40%;
    width: 100%;
}
#game1AStep2segmentation{
    height: 40%;
    width: 100%;
}
#game1AStep3segmentation{
    height: 40%;
    width: 100%;
} */
.icon-container {
  border-radius: 28px;
  border: 0.8px solid #fcd16b;
  background: linear-gradient(90deg, #f0504c 0%, #0c399f 98.5%);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.7);
}

.ai-container {
  clip-path: circle(50% at 50% 50%);
  border-radius: 28px;
  border: 0.8px solid #ffda21;
  background: #ffda21;
  box-shadow: 0px 4px 4px 0px rgba(253, 253, 14, 0.15);
  color: white;
}
.button-class {
  border-radius: 5px;
  border: 1px solid #1d47b0;
  font-weight: 500;
  background: linear-gradient(180deg, #279df3 0%, #0466ad 100%);
  box-shadow: 3px 4px 4px 0px #3072d6 inset;
}

.help {
  background: white;
  position: fixed;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border: 4px solid #99c8f3;
}

.help:before {
  content: "";
  position: absolute;
  top: 30%;
  left: -13px;
  height: 20px;
  width: 20px;
  background: white;
  border-radius: 5px;
  transform: rotate(45deg);
  border-left: inherit;
  border-bottom: inherit;
  box-shadow: inherit;
  margin-top: -10px;
}

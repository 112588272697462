@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input::placeholder {
  color: #718096;
  opacity: 1;
}

.react-datepicker-wrapper input:disabled {
  background-color: #ffffff;
  opacity: 0.1;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 35px !important;
  width: 100%;
}

/* .Toastify__toast-container--bottom-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	text-align: center;
}

.Toastify__close-button {
	position: relative;
	left: -95%;
	top :2%;
} */

.bg-zinc-200 {
  background: rgba(159, 146, 210, 0.2) !important;
}
.bg-zinc-500 {
  background: rgba(164, 135, 203, 0.4) !important;
}
.bg-zinc-900 {
  background: rgba(229, 211, 252, 0.4) !important;
}
.border-slate-400 {
  background: rgba(255, 255, 255, 0.1) !important;
}

.text-yellowwt {
  font-weight: 600;
}

.price-hexagon {
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 50%, 0% 0%);
}

.justify-start {
  justify-content: flex-start;
}
.items-start {
  align-items: flex-start;
}
.content-start {
  align-content: flex-start;
}

.price-color {
  background: navy;
}
.button-class {
  border-radius: 5px;
  border: 1px solid #1d47b0;
  font-weight: 500;
  background: linear-gradient(180deg, #279df3 0%, #0466ad 100%);
  box-shadow: 3px 4px 4px 0px #3072d6 inset;
}

.inventory
  {
    background-color:#FFFFFF;
    border-radius:120px;
  }

  .inventory-color {
    background: #2196F3;
  }
  .total-inventory-color{
    background: #F04F4B;

  }
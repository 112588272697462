.bg-zinc-200 {
  background-color: rgba(255, 255, 255, 0.14);
}

.bg-zinc-500 {
  background-color: rgba(255, 255, 255, 0.25);
}
.bg-zinc-900 {
  background-color: rgba(255, 255, 255, 0.4);
}
.text-yelloworange {
  color: #fcd16b;
  font-weight: 700;
}
.arrow-yelloworange {
  color: #fcd16b;
  font-weight: 700;
  font-size: larger;
}

.price-hexagon {
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}

.justify-start {
  justify-content: flex-start;
}
.items-start {
  align-items: flex-start;
}
.content-start {
  align-content: flex-start;
}

.price-color {
  background: navy;
}

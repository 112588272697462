/* CustomLineChart.css */
.no-pointer-events-label {
  pointer-events: none;
  color: yellow;
  font-weight: 700;
  position: absolute;
  z-index: 999 !important;
  pointer-events: cursor;
  text-align: center;
}
circle {
  stroke: none;
}

.highcharts-tracker-line {
  touch-action: none;
}
.highcharts-annotation-labels {
  rotate: 90;
}
/* .custom-label {
  text-align: center;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 400;
  height: 20px;
} */
iframe#webpack-dev-server-client-overlay {
  display: none;
}
.highcharts-container {
  height: 100% !important;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
#container {
  height: 100% !important;
}
.custom-chart-container {
  height: 100% !important;
}

.charthelp {
  background: white;
  position: fixed;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border: 4px solid #99c8f3;
}
.charthelp:before {
  content: "";
  position: absolute;
  top: 30%;
  right: -13px;
  height: 20px;
  width: 20px;
  background: white;
  border-radius: 5px;
  transform: rotate(45deg);
  border-right: inherit;
  border-top: inherit;
  box-shadow: inherit;
  margin-top: -10px;
}
.rmsc {
  --rmsc-main: #4285f4;
  --rmsc-hover: rgba(0, 153, 255, 0.173);
  --rmsc-selected: rgba(0, 153, 255, 0.173);
  --rmsc-border: #ccc;
  --rmsc-gray: rgb(253 224 71);
  --rmsc-bg: white;
  --rmsc-p: 10px;
  --rmsc-radius: 0.375rem;
  --rmsc-h: 38px;
}
.rmsc .dropdown-container {
  position: relative;
  outline: 0;
  background-color: #d8d8d852 !important;
  border: 1px solid var(--rmsc-border);
  border-radius: 0.375rem;
  width: 13rem;
}
.rmsc .dropdown-heading .dropdown-heading-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  color: yellow;
  font-size: 1.225rem;
  line-height: 1.85rem;
}
